<template>
  <div class="row">

    <h4>{{contractData.employee.first_name}} {{contractData.employee.prefix}} {{contractData.employee.last_name}}</h4>

    <div style="width: 300px">
      <label>{{ $t('form.email') }}</label>
      <label>{{ $t('employee.employment') }}</label>
      <label>{{ $t('employee.salary') }}</label>
      <template v-if="contractData.job_function.employment_type == 'fulltime' || contractData.job_function.employment_type == 'parttime'">
        <label>{{ $t('employee.roster_1') }}</label>
        <label>Week 1</label>
        <label>Week 2</label>
        <label>{{ $t('employee.hours') }}</label>
      </template>
      <label v-if="contractData.job_function.employment_type && showSkilledWorker">{{ $t('employee.profession') }}</label>
      <label>{{ $t('employee.job_title') }}</label>
      <label v-if="showReferenceAlt">{{ $t('employee.reference_number') }}</label>
      <label>{{ $t('employee.department') }}</label>
      <label>{{$t('employee.comp_travel')}}</label>
      <label>{{$t('employee.work_home_compensation')}}</label>
      <label>{{$t('employee.comp_expenses')}}</label>
      <label>{{$t('employee.comp_phone')}}</label>
      <label>Type contract</label>
      <label>{{$t('employee.contract_date_start')}}</label> 
      <label v-if="contractData.job_function.fixed_term">{{$t('employee.contract_date_end')}}</label>
      <label>Proeftijd?</label>
      <label>Contract gegenereerd?</label>
      <template v-if="contractData.generate_contract === 1">
        <label>Template</label>
        <label>Aanhangsels</label>
        <label>{{ $t('employee.contract_date') }}</label>
        <label>{{ $t('employee.contract_place') }}</label>
      </template>
      <template v-if="contractData.employee.iban">
        <label>&nbsp;</label>
        <label>{{ $t('form.address') }}</label>
        <label>{{ $t('form.postal_code') }}</label>
        <label>{{ $t('form.gender') }}</label>
        <label>{{ $t('form.pob') }}</label>
        <label>{{ $t('form.country') }}</label>
        <label>{{ $t('form.dob') }}</label>
        <label>{{ $t('form.phone') }}</label>
        <label>{{ $t('form.nationality') }}</label>
        <label>{{ $t('form.bsn') }}</label>
        <label>IBAN</label>
      </template>
    </div>

    <div class="set-margins" style="width: 500px">
      <div>{{contractData.employee.email}}&nbsp;</div>
      <div>{{$t('employee.employment_type_' + contractData.job_function.employment_type)}}&nbsp;</div>
      <div>€ {{formatCurrency(contractData.job_function.salary)}}&nbsp;</div>
      <template v-if="contractData.job_function.employment_type == 'fulltime' || contractData.job_function.employment_type == 'parttime'">
        <div class="row employee-schedule">
          <div class="col p-0" v-for="weekday in weekdays" :key="weekday">{{weekday}}&nbsp;</div>
        &nbsp;</div>
        <div class="row employee-schedule">
          <div class="col p-0" v-for="n in 7" :key="n">{{schedule[n-1]}}&nbsp;</div>
        &nbsp;</div>
        <div class="row employee-schedule mt-1">
          <div class="col p-0" v-for="n in 7" :key="n">{{schedule[6+n]}}&nbsp;</div>
        &nbsp;</div>
        <div>{{getHoursCleaned(contractData.job_function.hours)}}&nbsp;</div>
      </template>

      <div v-if="contractData.job_function.employment_type && showSkilledWorker">
        {{contractData.job_function.skilled_worker == 1 ? $t('employee.profession_1') : (contractData.job_function.skilled_worker === 0 ? $t('employee.profession_2') : $t('employee.profession_3'))}}
      &nbsp;</div>

      <div>{{getFunctionName(contractData.job_function)}} &nbsp;</div>
      <div v-if="showReferenceAlt">{{contractData.job_function.reference_number}} &nbsp;</div>
      <div>{{getDepartmentName(contractData.job_function)}} &nbsp;</div>
      <div>{{contractData.job_function.travel_compensation_type == 'none' ? 'n.v.t.' : ('€ ' + formatCurrency(contractData.job_function.travel_compensation) + ' per ' + (contractData.job_function.travel_compensation_type == 'distance' ? 'k/m' : 'maand'))}} &nbsp;</div>
      <div>{{workHomeType == 'none' ? 'n.v.t.' : ('€ ' + formatCurrency(contractData.job_function.work_days_at_home_compensation))}} &nbsp;</div>
      <div>{{contractData.job_function.expenses_compensation ? ('€ ' + formatCurrency(contractData.job_function.expenses_compensation)) : 'n.v.t.'}} &nbsp;</div>
      <div>{{contractData.job_function.phone_compensation ? ('€ ' + formatCurrency(contractData.job_function.phone_compensation)) : 'n.v.t.'}} &nbsp;</div>
      <div>{{contractData.job_function.fixed_term ? $t('employee.contract_limited') : $t('employee.contract_unlimited')}} &nbsp;</div>
      <div>{{formatDate(contractData.job_function.start_date, 'shortDate')}} &nbsp;</div>
      <div v-if="contractData.job_function.fixed_term">{{formatDate(contractData.job_function.fixed_term_expiration_date, 'shortDate')}}&nbsp;</div>
      <div>{{contractData.job_function.probation ? ('tot ' + formatDate(contractData.job_function.probation_expiration_date, 'shortDate')) : $t('form.no')}} &nbsp;</div>
      <div>{{contractData.generate_contract ? $t('form.yes') : $t('form.no')}} &nbsp;</div>
      <template v-if="contractData.generate_contract === 1">
        <div v-for="template in companyTemplates" :key="template.id" v-show="template.id == contractData.template_id">{{ template.name }}&nbsp;</div>
        <div v-show="contractData.attachments.length > 0"> {{ contractData.attachments.map(att => att.name).join(', ') }}&nbsp;</div>
        <div v-show="contractData.attachments.length == 0">{{ $t('form.no_selected', [$t('navigation.attachments')]) }}&nbsp;</div>
        <div> {{formatDate(contractData.generate_date, 'shortDate')}} &nbsp;</div>
        <div> {{contractData.generate_place}} &nbsp;</div>
      </template>

      <template v-if="contractData.employee.iban">
        <div>&nbsp;</div>
        <div>{{contractData.employee.address.street}} {{contractData.employee.address.house_number}}, {{contractData.employee.address.place}}</div>
        <div>{{contractData.employee.address.postal_code}}</div>
        <div>{{$t('form.gender_' + contractData.employee.gender.toLowerCase())}} &nbsp;</div>
        <div>{{contractData.employee.birth_place}} &nbsp;</div>
        <div>{{getCountryName(contractData.employee.birth_country_id)}} &nbsp;</div>
        <div>{{formatDate(contractData.employee.birth_date, 'shortDate')}} &nbsp;</div>
        <div>{{contractData.employee.phone_number}} &nbsp;</div>
        <div>{{getNationalityName(contractData.employee.nationality)}} &nbsp;</div>
        <div>{{contractData.employee.bsn_number}} &nbsp;</div>
        <div>{{contractData.employee.iban}} &nbsp;</div>
      </template>

    </div>

    <div class="d-flex w-100 justify-content-between pt-3">
      <button type="button" class="btn px-5 secondary-button" @click="back">{{ $t('form.back') }}</button>
      <button type="button" class="btn px-5 action-button mx-3" @click="download"><i class="fa fa-download"></i>&ensp;{{ $t('overview.download_pdf') }}</button>
    </div>

  </div>

  <hr class="text-white">
  <div id="inv_button"></div>
</template>

<script>
  import contractService from '@/services/ContractService'
  import countryService from '@/services/CountryService'
  import nationalityService from '@/services/NationalityService'
  import templateService from '@/services/TemplateService'
  import store from '@/store/user'
  export default {
    name: 'Home',
    data() {
      return{
        isEditing: false,
        formSection: 1,
        templateHeader: "",
        templateFooter: "",
        articles: [],
        countries: [],
        nationalities: [],
        templates: [],
        attachments: [],
        companyTemplates: [],
        companyAttachments: [],
        jobFunctions: [],
        jobDepartments: [],
        documentTypes: [],
        infoTexts: {},
        companyData: {},
        weekdays: ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'],
        isEmployeeFillingInNAW: false,
        shouldGenerateContract: true,
        nawData: {
            "email": "",
            "first_name": "",
            "initials": "",
            "prefix": null,
            "last_name": "",
        },
        oldContractData: {},
        contractData: {
          "employee": {
              "first_name": "",
              "last_name": "",
              "email": "",
              "phone_number": ""
          },
          "job_function": {
              "name": "",
              "reference_number": "",
              "department": "",
              "start_date": "",
              "fixed_term": null,
              "fixed_term_expiration_date": null,
              "probation": false,
              "probation_expiration_date": "",
              "probation_expiration_month": 0,
              "fixed_term_expiration_month": 0,
              "employment_type": "",
              "skilled_worker": null,
              "timetable": "8,8,8,8,8,0,0,8,8,8,8,8,0,0",
              "hours": 40,
              "salary": "",
              "travel_compensation_type": "none",
              "tax_compensation": 0,
              "tax_compensation_days": null,
              "tax_compensation_distance": null,
              "additional_compensations": null,
              "travel_compensation": null,
              "travel_compensation_days": null,
              "travel_compensation_distance": null,
              "expenses_compensation": null,
              "phone_compensation": null,
              "direct_upload": null,
              "has_wage_tax_credit": 0,
              "total_work_days_at_home": null,
              "work_days_at_home_compensation": null,
              "debtor_function_id": null,
              "debtor_department_id": null
          },
          "generate_contract": null,
          "generate_date": null,
          "generate_place": null,
          "template_id": null,
          "document_view": null,
          "document": null,
          "signature": null,
          "sync": null,
          "company_id": null,
          "attachments": []
        },
        mail: { email_to: "", subject: "", email_body: "" },
        schedule: ['8','8','8','8','8','0','0','8','8','8','8','8','0','0'],
        dict: {},
        pellEditor: null,
        editingProfession: false
      }
    },
    methods: {
      back(){
        this.$router.push({ name: "employees" });
      },
      download(){
        let item = {...this.contractData};
        contractService.download(item.id).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const companyName = this.getCompany().replaceAll(' ', '-');
          const pdfName = companyName + "_" + item.employee.first_name.replace(' ', '-') + '_' + item.employee.last_name.replace(' ', '-') + "_" + item.generate_date;
          link.setAttribute("download", pdfName + ".pdf");
          document.body.appendChild(link);
          link.click();
        }).catch(this.toastError);
      },
      getCompany(){
        const name = store.getters.getCurrentCompanyName;
        if(!name){
          const el = document.getElementById('company-select');
          if(!el || el.selectedIndex === -1) return '-';
          return el.options[el.selectedIndex].innerHTML;
        }
        return name;
      },
      getCountryName(id){
        const country = this.countries.find(country => country.id == id);
        return country ? country.name : '';
      },
      getNationalityName(id){
        const nationality = this.nationalities.find(nat => nat.id == id);
        return nationality ? nationality.name : '';
      },
      getFunctionName(data){
        if(data.name && data.name != '-') return data.name;
        const functionData = this.jobFunctions.find(func => func.id == data.debtor_function_id);
        return functionData ? functionData.name : '';
      },
      getDepartmentName(data){
        if(data.department && data.department != '-') return data.department;
        const functionData = this.jobDepartments.find(func => func.id == data.debtor_department_id);
        return functionData ? functionData.name : '';
      },
      loadData(){
        countryService.index().then(response => {
          this.countries = response.data;
          this.countries.sort(function(x,y){ return x.name =='Nederland'  ? -1 : y.name =='Nederland' ? 1 : 0; });
        }).catch(this.toastError);
        nationalityService.index().then(response => {
          this.nationalities = response.data;
          this.nationalities.sort(function(x,y){ return x.name =='Nederlandse'  ? -1 : y.name =='Nederlandse' ? 1 : 0; });
        }).catch(this.toastError);
        templateService.index().then(response => {
          this.attachments = response.data.filter(template => template.is_appendage);
          this.templates = response.data.filter(template => !template.is_appendage);
          const currentCompId = store.getters.getCurrentCompanyId;
          this.companyTemplates = this.templates.filter(template => template.companies.find(comp => comp.id == currentCompId) != null);
          this.companyAttachments = this.attachments.filter(template => template.companies.find(comp => comp.id == currentCompId) != null);
        }).catch(this.toastError);     
        this.jobFunctions = store.getters.getDepartments;
        this.jobDepartments = store.getters.getFunctions;
      },

      getHoursCleaned(value){
        const rvalue = value.toFixed(2).replace('.', ',').replace(',00', '');
        return rvalue[rvalue.length - 1] === '0' && rvalue.includes(',') ? rvalue.slice(0, -1): rvalue;
      },
    },
    mounted() {
      window.setTimeout(() => {
        this.loadData();

        const href = window.location.href;
        const splitHref = href.split('/');
        const id = splitHref[splitHref.length - 1];

        this.formSection = 1;
        this.isEditing = true;
        this.currentEmployeeStep = 1;
        contractService.show(id).then(response => {
          this.contractData = response.data;
          if(!response.data.attachments){
            this.contractData.attachments = [];
          }
          if(!response.data.employee){
            this.contractData.employee = {
              "first_name": "Medewerker",
              "prefix": "niet",
              "last_name": "gevonden"
            }
          }
          this.schedule = this.contractData.job_function.timetable.split(',');
          this.workHomeType = this.contractData.job_function.has_wage_tax_credit ? (this.contractData.job_function.total_work_days_at_home ? "calculate" : "monthly") : "none";
        }).catch(this.toastError);
        
      }, 100);
    }
  }
</script>